<template>
  <core-section id="what-my-clients-say">
    <abstract-4 />

    <core-heading>Clients I've worked with.</core-heading>
    <template v-if="logos.length">
      <v-col
        v-for="(logo, i) in logos"
        :key="`logo-${i}`"
        cols="12"
        md="3"
      >
        <v-img
          :src="require(`@/assets/clients/${logo}`)"
          contain
          height="75"
        />
      </v-col>
    </template>
  </core-section>
</template>

<script>
  export default {
    name: 'WhatMyClientsSay',

    components: {
      Abstract4: () => import('@/components/Abstract4'),
      // Client: () => import('@/components/Client'),
    },

    data: () => ({
      clients: [
        {
          quote: 'Aliquam sit amet eros id lorem posuere lobortis ut id enim. Proin ullamcorper fringilla risus et lacinia.Aliquam eget lacus a libero.',
          name: 'J. Williams',
          company: 'Motion',
          avatar: 'Client1.png',
        },
        {
          quote: 'Aliquam sit amet eros id lorem posuere lobortis ut id enim. Proin ullamcorper fringilla risus et lacinia.Aliquam eget lacus a libero.',
          name: 'A. Long',
          company: 'Classic Cafe',
          avatar: 'Client2.png',
        },
        {
          quote: 'Aliquam sit amet eros id lorem posuere lobortis ut id enim. Proin ullamcorper fringilla risus et lacinia.Aliquam eget lacus a libero.',
          name: 'M. Hernandez',
          company: 'Flagship',
          avatar: 'Client3.png',
        },
        {
          quote: 'Aliquam sit amet eros id lorem posuere lobortis ut id enim. Proin ullamcorper fringilla risus et lacinia.Aliquam eget lacus a libero.',
          name: 'L. McCannon',
          company: 'Motion',
          avatar: 'Client4.png',
        },
        {
          quote: 'Aliquam sit amet eros id lorem posuere lobortis ut id enim. Proin ullamcorper fringilla risus et lacinia.Aliquam eget lacus a libero.',
          name: 'A. Mah',
          company: 'Classic Cafe',
          avatar: 'Client5.png',
        },
        {
          quote: 'Aliquam sit amet eros id lorem posuere lobortis ut id enim. Proin ullamcorper fringilla risus et lacinia.Aliquam eget lacus a libero.',
          name: 'H. Russo',
          company: 'Flagship',
          avatar: 'Client6.png',
        },
      ],
      logos: [
        'google.png',
        'linkedin.png',
        'cbs.png',
        'yamaha.png',
        'etail.png',
        'fico.png',
        'appian.png',
        'premier.png',
      ],
    }),
  }
</script>
